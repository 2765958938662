<template>
    <div class="grid">
        <h1>Wallet</h1>
        <!-- <div class="col-12 md:col-4">
            <div class="card mb-0"  v-if="balanceLoading">
                <Skeleton width="100%" height="5rem"></Skeleton>
            </div>
            <div class="card mb-0" v-else>
                <div class="flex justify-content-between mb-3">
                    <div class="py-1">
                        <span class="block text-500 font-medium text-xl mb-3">Total Online Balance</span>
                        <div class="text-900 font-semibold text-4xl">&#x09F3; {{balance.balance}}</div>
                    </div>
                    <div class="flex align-items-center justify-content-center bg-blue-100 border-round" style="width:2.5rem;height:2.5rem">
                        <i class="pi pi-wallet text-blue-500 text-xl"></i>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 md:col-4">
            <div class="card mb-0"  v-if="balanceLoading">
                <Skeleton width="100%" height="5rem"></Skeleton>
            </div>
            <div v-else class="card mb-0">
                <div class="flex justify-content-between mb-3">
                    <div>
                        <span class="block text-500 font-medium">Deposites</span>
                        <div class="text-900 font-medium text-xl mb-1">{{balance.deposites_count}}</div>
                        <span class="block text-500 font-medium">Amount</span>
                        <div class="text-900 font-medium text-xl">&#x09F3; {{balance.deposites_amount}}</div>
                    </div>
                    <div class="flex align-items-center justify-content-center bg-green-100 border-round" style="width:2.5rem;height:2.5rem">
                        <i class="pi pi-arrow-up text-green-500 text-xl"></i>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 md:col-4">
            <div class="card mb-0"  v-if="balanceLoading">
                <Skeleton width="100%" height="5rem"></Skeleton>
            </div>
            <div v-else class="card mb-0">
                <div class="flex justify-content-between mb-3">
                    <div>
                        <span class="block text-500 font-medium">Withdrawals</span>
                        <div class="text-900 font-medium text-xl mb-1">{{balance.withdrawal_count}}</div>
                        <span class="block text-500 font-medium">Amount</span>
                        <div class="text-900 font-medium text-xl">&#x09F3; {{balance.withdrawal_amount}}</div>
                    </div>
                    <div class="flex align-items-center justify-content-center bg-orange-100 border-round" style="width:2.5rem;height:2.5rem">
                        <i class="pi pi-arrow-down text-orange-500 text-xl"></i>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- <div class="col-12">
            <div class="p-card p-2">
                <div class="surface-ground border-1 surface-border p-2">
                    <div class="grid md:mt-2">
                        <div class="col-12">
                            <div class="text-xl font-semibold py-2">Transection List</div>
                        </div>
                        <div class="col-12 md:col-4 md:col-offset-4">
                            <div class="field" >
                                <span class="p-float-label">
                                    <Dropdown
                                        class="w-full"
                                        id="operations"
                                        v-model="operation"
                                        :options="operations"
                                        optionValue="value"
                                        optionLabel="name"
                                    />
                                    <label for="operations">Operation</label>
                                </span>
                            </div>
                        </div> -->
                        <!-- //commented -->
                        <!-- <div class="col-12 md:col-4">
                            <div class="grid">
                                <div class="col-6">
                                    <div class="field" >
                                        <span class="p-float-label">
                                            <Calendar
                                                class="w-full"
                                                inputId="range"
                                                v-model="dateStart"
                                                dateFormat="dd-mm-yy"
                                                :manualInput="false"
                                                @date-select="changeStartDate"
                                                v-tooltip.top="'Start and end date is must to get a result.'"
                                            />
                                            <label for="range">Start Date</label>
                                        </span>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="field" >
                                        <span class="p-float-label">
                                            <Calendar
                                                class="w-full"
                                                inputId="range"
                                                v-model="dateEnd"
                                                dateFormat="dd-mm-yy"
                                                :manualInput="false"
                                                v-tooltip.top="'Start and end date is mustto get a result.'"
                                            />
                                            <label for="range">End Date</label>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <!-- <div class="col-12 md:col-4">
                            <div class="flex flex-wrap">
                                <div class="flex-1 flex align-items-center justify-content-center">
                                    <Button label="Clear" icon="pi pi-filter-slash" class="p-button-raised p-button-text  w-full" @click="clearFilters"></Button>
                                </div>
                                <span class="mx-2"></span>
                                <div class="flex-1 flex align-items-center justify-content-center">
                                    <Button label="Apply" icon="pi pi-filter" class="p-button-raised p-button-text  w-full" @click="getFilteredList"></Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="custom-skeleton" v-if="walletLoading">
                    <Skeleton width="100%" height="4rem" class="my-1"></Skeleton>
                    <Skeleton width="100%" height="4rem" class="my-1"></Skeleton>
                    <Skeleton width="100%" height="4rem" class="my-1"></Skeleton>
                    <Skeleton width="100%" height="4rem" class="my-1"></Skeleton>
				</div>
                <div v-else>
                    <DataTable v-if="walletList.data != ''" ref="dt" :value="walletList.data" dataKey="id"  responsiveLayout="scroll">
                        <Column header="ID">
                            <template #body="slotProps">
                                #00{{slotProps.data.id}}
                            </template>
                        </Column>
                        <Column field="date" header="Date" headerStyle="min-width:8rem;">
                            <template #body="slotProps">
                                {{formatDate(slotProps.data.date)}}
                            </template>
                        </Column>
                        <Column header="Operation">
                            <template #body="slotProps">
                                <div :class="slotProps.data.operation == 'Withdrawal'? 'text-orange-500' : ''">
                                    {{slotProps.data.operation}}
                                </div>
                            </template>
                        </Column>
                        <Column header="Amount">
                            <template #body="slotProps">
                                <span v-if="slotProps.data.operation == 'Withdrawal'">-</span>
                                <span v-else>+</span>
                                {{slotProps.data.amount}}
                            </template>
                        </Column>
                        <template #footer>
                            <div class="flex">
                                <span class="flex-1 flex align-items-center justify-content-center">Totals:</span>
                                <span class="flex-1 flex align-items-center justify-content-center">{{currentBalance}}</span>
                            </div>
                            <Divider/>
                            <Paginator v-model:first="walletList.from" :rows="paginationValue" :totalRecords="walletList.total" :rowsPerPageOptions="[2,10,20,50]" @page="onPage" >
                            </Paginator>
                        </template>
                    </DataTable>
                    <template v-else>
                        <div class="col">
                            <div class="flex align-items-stretch justify-content-center flex-wrap card-container" style="min-height: 200px">
                                <div class="flex align-items-center justify-content-center">
                                    <Message severity="info" :closable="false">No transection found!</Message>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div> -->
    </div>
</template>

<script>
// import { ref } from 'vue'
// import { useRoute, useRouter } from 'vue-router';
// import Axios from 'axios';
// import moment from 'moment';
// export default {
//     setup () {
//         const router = useRouter();
// 		const route = useRoute();

//         const balance = ref();
//         const balanceLoading = ref(true);
//         const walletList = ref();
//         const walletLoading = ref(true);
//         const operation = ref();
//         const dateRange = ref([]);
//         const dateStart = ref();
//         const dateEnd = ref();

//         const currentBalance = ref(0);

//         const paginationValue = ref(10);
// 		const pageValue = ref();

//         // const currentDate = ref(new Date());
//         // dateEnd.value = moment(currentDate.value).format("DD-MM-YYYY");
//         // const startDate = ref();

//             // console.log('a'+currentDate.value);
//             // currentDate.value.setDate(currentDate.value.getDate() - 30);
//             // console.log('s'+moment(currentDate.value.setDate(currentDate.value.getDate() - 30)).format("DD-MM-YYYY"));
//             // startDate.value = moment(currentDate.value.setDate(currentDate.value.getDate() - 30)).format("DD-MM-YYYY");
//             // startDate.value = currentDate.value.toISOString().split('T')[0];
//             // console.log('s'+startDate.value);
//             // dateStart.value = moment(startDate.value).format("DD-MM-YYYY");

//             // console.log('b'+currentDate.value, 'c'+startDate.value);
//             // dateRange.value[0] = startDate.value;
//             // dateRange.value[1] = currentDate.value;

//             var daysDate = [];
// // for(var i = 1; i<= 30; i++) {
//     var date = new Date();
//     daysDate.push(moment(date).format("YYYY-MM-DD"));
//     daysDate.push(moment(date.setDate(date.getDate() - 30)).format("YYYY-MM-DD"));
//     dateStart.value = daysDate[1];
//     dateEnd.value = daysDate[0];
// // }
// console.log(daysDate);
// console.log(dateStart.value);
//     console.log(dateEnd.value);
//         const operations = ref([
//             {value: 'Deposite', name: 'Deposite'},
//             {value: 'Withdrawal', name: 'Withdrawal'},
//         ]);

//         const getActiveQuerys = () => {
//             if(route.query.page != null){
//                 pageValue.value = route.query.page;
//             }
//             if(route.query.pagination != null){
//                 paginationValue.value = parseInt(route.query.pagination);
//             }
//             if(route.query.operation != null){
//                 operation.value = route.query.operation;
//             }
//             if(route.query.date != null){
//                 dateRange.value = route.query.date;
//             }
//             // if(route.query.validAt != null){
//             //     validAt.value = new Date(route.query.validAt);
//             // }
//         };
//         getActiveQuerys();

//         const getBalance = () => {
//             Axios
//             .get("api/operator/wallet/balance")
// 			.then((res) => {
// 				if (res.data.response == "success") {
//                     balanceLoading.value = false;
//                     balance.value = res.data.data;
//                 }else{
//                     balanceLoading.value = true;
//                     console.log(res.data.message);
//                 }
//             })
//             .catch(err => {
//                 console.log(err);
//             })
//         }
//         getBalance();

//         const getWalletList = () => {
//             if(dateStart.value){
//                 dateStart.value = new Date(dateStart.value);
//                 console.log(dateStart.value);
//                 dateRange.value[0] = moment(dateStart.value).format("YYYY-MM-DD HH:mm:ss");
//             }
//             if(dateEnd.value){
//                 dateEnd.value = new Date(dateEnd.value)
//                 dateRange.value[1] = moment(dateEnd.value).format("YYYY-MM-DD HH:mm:ss");
//             }
//             console.log(dateRange.value);
//             Axios
//             .get("api/admin/wallet/list",{
//                 params: {
//                     page: pageValue.value,
//                     pagination: paginationValue.value,
//                     operation: operation.value,
//                     date: dateRange.value,
//                 }
//             })
//             .then(res => {
//                 console.log(res);
//                 if (res.data.response == "success") {
//                     walletLoading.value = false;
//                     walletList.value = res.data.data;
//                     walletList.value.from = walletList.value.from-1;

//                     for(let item of walletList.value.data){
//                         if (item.operation == 'Deposite') {
//                             currentBalance.value += item.amount;
//                         }else{
//                             currentBalance.value -= item.amount;
//                         }
//                     }
//                 }else{
//                     console.log(res.data.message);
//                 }
//             })
//             .catch(err => {
//                 walletLoading.value = true;
//                 console.log(err);
//             })
//         }
//         getWalletList();

//         const changeStartDate = () => {
//             dateEnd.value = null;
//         };

//         const getFilteredList = () => {
//             walletLoading.value = true;
//             currentBalance.value = 0;
//             // showFilterMenu.value = false;

//             var queryParams = {};
//             if(operation.value != null){
//                 queryParams.operation = operation.value;
//             }
//             if(dateRange.value != null){
//                 queryParams.date = dateRange.value;
//             }

//             router.replace({ name: 'wallet', query: { ...queryParams } });

//             getWalletList();
//         };

//         const clearFilters = () => {
//             walletLoading.value = true;
//             currentBalance.value = 0;
//             // showFilterMenu.value = false;

//             operation.value = null;
//             dateRange.value = '';
//             dateStart.value = null;
//             dateEnd.value = null;
//             router.replace();

//             getWalletList();
//         };

//         const onPage = (event) => {
// 			pageValue.value = event.page + 1;
// 			paginationValue.value = event.rows;
// 			walletLoading.value = true;
//             currentBalance.value = 0;
//             // showFilterMenu.value = false;

//             var queryParams = {};
//             if(pageValue.value != null){
//                 queryParams.page = pageValue.value;
//             }
//             if(paginationValue.value != null){
//                 queryParams.pagination = paginationValue.value;
//             }
//             router.replace({ name: 'wallet', query: { ...queryParams } });

//             getWalletList();
// 		}

//         const formatDate = (rawDate) => {
//             return moment(rawDate).format("DD-MM-YYYY hh:mm:ss A");
//         }
        

//         return {
//             balance,
//             balanceLoading,
//             walletList,
//             walletLoading,
//             formatDate,
//             paginationValue,
//             pageValue,
//             onPage,
//             operations,
//             operation,
//             dateRange,
//             dateStart,
//             dateEnd,
//             getFilteredList,
//             clearFilters,
//             currentBalance,
//             changeStartDate
//         }
//     }
// }
</script>

<style>

</style>